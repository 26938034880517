export function getCartQuantity() {
    jQuery.getJSON(`${window.location.origin}/index.php?option=com_snv2&task=getCartQuantity&format=json`, function ({data}) {
        if (jQuery('.cart__counter').length > 0) {
            jQuery('.cart__counter').text(data)
        }
    })
}

export function getCartDetails() {
    jQuery.getJSON(`${window.location.origin}/index.php?option=com_snv2&task=getCartDetails&format=json`, function(data) {
        const basket_url = jQuery('a.fright').attr('data-url')
        const cartItems = data.data.items
        let cartPreview = '',
            cartRows = '',
            itemsSum = 0
        for (const cartItem of cartItems) {
            itemsSum += parseInt(cartItem.amount)
            cartRows += `<div class="row">`
            cartRows += `<div class="col-md-3 minicart__image"><img src="${cartItem.image}" alt="${cartItem.name}" title="${cartItem.productAlias}" /></div>`
            cartRows += `<div class="col-md-9 minicart__title">`
            cartRows += cartItem.amount > 1 ? cartItem.amount + ' x ' : ''
            cartRows += `<a class="inverted" href="${cartItem.url}">${cartItem.usp}</a> <span class="minicart__price">${cartItem.price}</span></div>`
            cartRows += `</div>`
        }
        cartRows += `<div class="row">`
        cartRows += `<div class="col-md-8">${Joomla.JText._('COM_SNV2_CHECKOUT_CART_SHIPPING_LABEL')}</div>`
        cartRows += `<div class="col-md-4 align-right">${data.data.shipment}</div>`
        cartRows += `</div>`
        cartRows += `<div class="row">`
        cartRows += `<div class="col-md-8">${Joomla.JText._('COM_SNV2_CHECKOUT_CART_SUMMARY_LABEL')}</div>`
        cartRows += `<div class="col-md-4 align-right"><b>${data.data.basketTotal}</b></div>`
        cartRows += `</div>`
        if (cartRows !== '') {
            cartPreview += `<h5>${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_HEADER')}</h5>`
            cartPreview += cartRows
            cartPreview += `<a class="promoproduct__cart ga-action" data-ga_category="MainPage" data-ga_action="basketclicked" href="${basket_url}">${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_BUTTON')}</a>`
        } else {
            cartPreview = `<small>${Joomla.JText._('TPL_SN_WEBSHOP_MINICART_EMPTY')}</small>`
        }
        jQuery('.minicart .header__cart-mini').html(cartPreview)
        jQuery('.cart__counter').text(itemsSum)
    })
}
