export default class HeaderPopup {
    static init(buttonSelector, popupSelector, closeSelector, openOnMobile = false) {

        if (jQuery(buttonSelector).length && jQuery(popupSelector).length) {
            const $button = jQuery(buttonSelector).first();
            const $popup = jQuery(popupSelector).first();
            const $close = jQuery(closeSelector).first();
            const $this = this;

            $close.on('click', () => {
                $this.closePopup($popup);
            });

            $button.click(function (e) {
                const isMobile = jQuery('.hmenu').css('display') !== 'flex';
                if (isMobile || openOnMobile) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!$popup.hasClass('fadeInDown')) {
                        $this.openPopup($popup);
                    } else {
                        $this.closePopup($popup);
                    }
                }
            });
        }
    }

    static closePopup($popup) {
        $popup.addClass('fadeOutUp');
        $popup.removeClass('fadeInDown');
    }

    static openPopup($popup) {
        $popup.removeClass('fadeOutUp');
        $popup.addClass('fadeInDown');
    }
}