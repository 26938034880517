const notSet = 'not set'; // deafult value when property is not set

export default class GtmPromoEvents {

    constructor() {
        this.initViewListener();
    }

    trackElements(elements) {
        this.setClickListener(elements);
        this.pushViewEvent(elements);
    }

    setClickListener(elements) {
        const $this = this;
        const $elements = jQuery(elements);
        if ($elements.length) {
            $elements.each((i, el) => {
                jQuery(el).on('click', c => {
                    $this.pushClickEvent(c.currentTarget);
                });
            });
        }
    }

    initViewListener() {
        const $this = this;
        jQuery(window).scroll(() => {
            if (this.timeout) {
                clearTimeout($this.timeout);
            }
            $this.timeout = setTimeout(() => {
                this.checkOnScreenElements();
            }, 200);
        });
    }

    checkOnScreenElements() {
        jQuery('[data-promo-click]:not(.gtm_viewed)').each((i, el) => {
            const $element = jQuery(el);
            if (this.isOnScreen($element)) {
                this.pushViewEvent($element);
            }
        });
    }

    isOnScreen(element) {
        const threshold = 30; // in px
        if (typeof element === 'undefined') {
            return false;
        }
        const $element = jQuery(element);

        if ($element.is(":hidden") || $element.css('opacity') === '0') {
            return false;
        }

        if ($element.parent().hasClass('carousel__item') && !$element.parent().hasClass('tns-slide-active')) {
            return false;
        }

        const elementTop = $element.offset().top;
        const elementBottom = elementTop + $element.outerHeight();
        const viewportTop = jQuery(window).scrollTop();
        const viewportBottom = viewportTop + jQuery(window).height();

        return (elementTop + threshold) > viewportTop && (elementBottom - threshold) < viewportBottom;
    };

    pushClickEvent(element) {
        const $element = jQuery(element);
        if ($element.hasClass('gtm_clicked')) {
            return;
        }
        const eventObject = this.prepareClickEvent($element);
        this.pushEvent(eventObject);
        $element.addClass('gtm_clicked');
    }

    pushViewEvent(elements) {
        setTimeout(() => { // We have to wait a bit to finish all the animation and make sure the element is on the screen.
            const $elements = jQuery(elements);
            $elements.each((i, el) => {
                const $el = jQuery(el);
                if (this.isOnScreen($el) && !$el.hasClass('gtm_viewed')) {
                    const eventObject = this.prepareViewEvent($el);
                    this.pushEvent(eventObject);
                    $el.addClass('gtm_viewed');
                }
            });
        }, 500);
    }

    prepareClickEvent($element) {
        if (window.newMarkestic) {
            const promotion = this.prepareNewPromotion($element);
            return {
                event: 'select_promotion',
                ecommerce: {
                    items: [promotion]
                }
            };
        } else {
            return {
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': $element.attr('data-seo-ac') || notSet,
                                'name': $element.attr('data-seo-name') || notSet,
                                'creative': $element.attr('data-seo-creative') || notSet,
                                'position': $element.attr('data-seo-position') || notSet,
                            }
                        ]
                    }
                }
            };
        }
    }

    prepareViewEvent($element) {
        if (window.newMarkestic) {
            const promotion = this.prepareNewPromotion($element);
            return {
                event: 'view_promotion',
                ecommerce: {
                    items: [promotion]
                }
            };
        } else {
            return {
                'event': 'promoView',
                'ecommerce': {
                    'promoView': {
                        'promotions': [
                            {
                                'id': $element.attr('data-seo-ac') || notSet,
                                'name': $element.attr('data-seo-name') || notSet,
                                'creative': $element.attr('data-seo-creative') || notSet,
                                'position': $element.attr('data-seo-position') || notSet,
                            }
                        ]
                    }
                }
            };
        }
    }

    prepareNewPromotion($element) {
        return {
            promotion_name: $element.attr('data-seo-name') || notSet,
            creative_name: ($element.attr('data-seo-creative') || notSet).replace(/^https?:\/\//, ''), // remove protocol
            location_id: $element.attr('data-seo-position') || notSet
        };
    }

    pushEvent(eventObject) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(eventObject);
    }
}
