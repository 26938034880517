import initTogglePasswordVisibility from './togglePasswordVisibility.js';
import GtmPromoEvents from './GtmPromoEvents.js';
import TemplateHelper from './helpers/TemplateHelper.js';
import HeaderPopup from './helpers/HeaderPopup.js';
import {postNL} from "./helpers/postNL";
import {getCartQuantity, getCartDetails} from './helpers/helpers'

require('./../../sass/template.scss');

jQuery.fn.isTopVisible = function () {
    const elementTop = jQuery(this).offset().top;
    const elementBottom = elementTop + jQuery(this).outerHeight();
    const viewportTop = jQuery(window).scrollTop();
    const viewportBottom = viewportTop + jQuery(window).height();
    return elementTop < viewportBottom;
};
document.addEventListener("DOMContentLoaded", function () {

    if (jQuery('html').attr('lang') === 'nl-nl') {
        jQuery('#jform_snprofile_address').parents(':eq(1)').hide();
        jQuery('#jform_snprofile_postal').parents(':eq(1)').hide();
        jQuery('#jform_snprofile_postal_code, #jform_snprofile_housenumber').on('keyup', function () {
            const postal_code = jQuery('#jform_snprofile_postal_code').val().replace(/_/g, "");
            if ((postal_code.length - 1) === 6 && jQuery('#jform_snprofile_housenumber').val().length > 0) {
                postNL(jQuery('#jform_snprofile_postal_code').val(), jQuery('#jform_snprofile_housenumber').val());
            }
        });
    }

    jQuery('.productview__ask').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        const pid = e.target.dataset.pid
        if (jQuery("#notifyme__form-" + pid).css('display') === 'none') {
            jQuery("#notifyme__form-" + pid).slideToggle();
        }
    });

    jQuery('.notifyme__close').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        const pid = e.target.dataset.pid
        jQuery(e.target).closest("#notifyme__form-" + pid).slideUp();
    });

    jQuery('.productview__button').on('click', (e) => {
        const formSelector = '.notifyme__form',
            $form = jQuery(formSelector),
            token = jQuery("input[name='pId']").next().attr('name');
        let formData = {
            "name": $form.find('#jform_name').val(),
            "email": $form.find('#jform_email').val(),
            "phone": $form.find('#jform_phone').val(),
            "tos_consent": $form.find('#jform_tos_consent').is(":checked") ? 1 : 0,
            "pId": $form.find("input[name=pId]").val(),
            'recaptcha': typeof grecaptcha !== 'undefined' ? grecaptcha.getResponse() : ''
        };
        const data = 'name=' + formData.name + '&email=' + formData.email + '&phone=' + formData.phone + '&tos_consent=' + formData.tos_consent + '&pId=' + formData.pId + '&recaptcha=' + formData.recaptcha + '&' + token + '=1';

        $form.submit(function (e) {
            e.stopPropagation();
            e.preventDefault();
        }).validate({
            ignore: "",
            rules: {
                "jform[tos_consent]": {
                    required: true
                },
                "jform[email]": {
                    required: true,
                },
                "jform[name]": {
                    required: true,
                    minlength: 2
                }
            },
            messages: {
                "jform[tos_consent]": {
                    required: $form.data("message-required")
                },
                "jform[email]": {
                    required: $form.data("message-required"),
                    regex: $form.data("message-required")
                },
                "jform[name]": {
                    required: $form.data("message-required"),
                    minlength: $form.data("message-required")
                }
            },
            errorClass: "has-error",
            errorPlacement: function (error, element) {
                error.insertBefore(element);
            },
            highlight: function (element, errorClass) {
                jQuery(element).parent().addClass("has-error");
            },
            submitHandler: function (form) {
                if (jQuery('#jform_captcha').length) {
                    grecaptcha.execute()
                        .then(() => {
                            if (!grecaptcha.getResponse()) {
                                return;
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else {
                    jQuery.ajax({
                        type: 'POST',
                        url: 'index.php?option=com_snv2&task=checkout.saveToNotificationList&tmpl=component&format=json',
                        data: data,
                        cache: false,
                        beforeSend: function () {
                            $form.find('.alert').hide();
                            $form.find('button[type=submit]').prop('disabled', true).addClass('disabled');
                        }
                    }).done(data => {
                        const status = typeof data.data !== 'undefined' ? data.data.status : 'error';
                        const message = typeof data.data !== 'undefined' ? data.data.message : 'Response error';
                        $form.find('button[type=submit]').prop('disabled', false).removeClass('disabled');
                        $form.find('.alert')
                            .removeClass('alert-error, alert-success')
                            .addClass(`alert-${status}`)
                            .html(message)
                            .show();
                        if (status === 'success') {
                            $form.find('> *').not('.alert').remove();
                        }
                    });
                }
            }
        });
        jQuery.validator.addMethod('jform[email]', function (value) {
            return /.+@.+\\..+/.test(value);
        }, $form.data("message-required"));
    });

    window.gtmPromoEvents = new GtmPromoEvents;
    window.gtmPromoEvents.trackElements(jQuery('[data-promo-click]:not(.promobox__figure)'));

    if (jQuery('body').hasClass('pl-PL')) {
        jQuery('#privacy_policy_modal').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            jQuery('#privacy_policy').modal();
        });
    }

    HeaderPopup.init('.header__account-button', '.header__account-popup', '.header__account-close', true);

    let active = false;
    var lazyLoad = function lazyLoad() {
        let lazyImages = [].slice.call(document.querySelectorAll("img.lazy, source.lazy"));
        if (active === false) {
            active = true;
            setTimeout(function () {
                lazyImages.forEach(function (lazyImage) {
                    if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {

                        if (typeof lazyImage.dataset.src !== 'undefined') {
                            lazyImage.src = lazyImage.dataset.src;
                        }
                        if (typeof lazyImage.dataset.srcset !== 'undefined') {
                            lazyImage.srcset = lazyImage.dataset.srcset;
                        }

                        lazyImage.classList.remove("lazy");

                        lazyImages = lazyImages.filter(function (image) {
                            return image !== lazyImage;
                        });

                        if (lazyImages.length === 0) {
                            document.removeEventListener("scroll", lazyLoad);
                            window.removeEventListener("load", lazyLoad);
                            window.removeEventListener("resize", lazyLoad);
                            window.removeEventListener("orientationchange", lazyLoad);
                        }
                    }
                });
                active = false;
            }, 200);
        }
    };

    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("load", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);

    TemplateHelper.initFullWidthMenu();

    // add to cart modal
    if (typeof atc_products !== 'undefined') {
        jQuery('form#cart__form, form#cart__form--bottom, form.cart__form--atc').on('submit', function (e) {
            if (atc_products === true && window.outerWidth > 1024 && jQuery('#add_to_cart_products_modal .modal-body .atc_product').length > 0) {
                e.preventDefault();
                e.stopPropagation();

                // hide cart preview
                jQuery('.minicart .header__cart-mini').removeClass('fadeInDown').addClass('fadeInUp');

                var submitbutton = jQuery(this).find('button');
                submitbutton.addClass('btn-loading');
                jQuery('#add_to_cart_products_modal #system-message-container').css('opacity', 0.3).find('.alert-message').html('<i class="fa fa-spinner fa-spin"></i>');
                jQuery('#add_to_cart_products_modal').modal({'show': true, 'backdrop': 'static', 'keyboard': false});

                // add product to cart
                var post_url = jQuery(this).attr("action");
                var form_data = jQuery(this).serialize();
                jQuery.post(post_url, form_data, function (response) {
                    submitbutton.removeClass('btn-loading');
                    var message = jQuery(response).find('#system-message-container').html();
                    jQuery('#add_to_cart_products_modal #system-message-container').html(message).css('opacity', 1).find('h4, a.close').remove();
                    jQuery('#add_to_cart_products_modal #system-message-container .hidden').removeClass('hidden');
                    if (jQuery('#add_to_cart_products_modal #system-message-container .gtm_addtocart').length === 1) {
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            "ecommerce": {
                                "currencyCode": currencyCode,
                                "add": {
                                    "products": [{
                                        "id": product_info.id,
                                        "name": product_info.name,
                                        "price": price,
                                        "category": category,
                                        "quantity": 1
                                    }]
                                }
                            },
                            "event": "addToCart"
                        });
                    }
                });
            }
        });

        if (jQuery('#add_to_cart_products_modal').length > 0 && window.outerWidth > 1024) {
            // add/remove product from "add to cart" modal
            jQuery('.product__checkbox').on('change', function () {
                var form = jQuery(this).parent();
                var action = jQuery(this).prop('checked') ? 'addToCart' : 'removeFromCart';
                form.find('input[name=task]').val('basket.' + action);
                var submitbutton = jQuery(this).find(' + label');
                var post_url = form.attr("action");
                var form_data = form.serialize();
                jQuery('#add_to_cart_products_modal #system-message-container').css('opacity', 0.3).find('.alert-message').html('<i class="fa fa-spinner fa-spin"></i>');
                submitbutton.addClass('btn-loading');
                jQuery.post(post_url, form_data, function (response) {
                    submitbutton.removeClass('btn-loading');
                    var message = jQuery(response).find('#system-message-container').html();
                    jQuery('#add_to_cart_products_modal #system-message-container').html(message).css('opacity', 1).find('h4, a.close').remove();
                    jQuery('#add_to_cart_products_modal #system-message-container .hidden').removeClass('hidden');
                    if (jQuery('#add_to_cart_products_modal #system-message-container .gtm_addtocart').length === 1) {
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            "ecommerce": {
                                "currencyCode": currencyCode,
                                "add": {
                                    "actionField": {
                                        "list": "Webshop"
                                    },
                                    "products": [{
                                        "id": product_info.id,
                                        "name": product_info.name,
                                        "price": price,
                                        "category": category,
                                        "quantity": 1
                                    }]
                                }
                            },
                            "event": "addToCart"
                        });
                    }
                });
            });

            // check products checkbox on modal when they are in the basket now
            var productsInBasket = jQuery('.header__cart-mini .row');
            if (productsInBasket.length > 0) {
                for (var i = productsInBasket.length - 1; i >= 0; i--) {
                    var product_id = jQuery(productsInBasket[i]).attr('product-id');
                    jQuery('#atc_product_' + product_id).prop('checked', true);
                }
            }
        }

        // refresh cart after back to product from "add to cart modal"
        if (jQuery('#atc_modal__back').length) {
            jQuery('#atc_modal__back').on('click', function () {
                getCartDetails();
            });
        } // refresh cart END
    } // add to cart modal END

    TemplateHelper.setBottomButtonPadding();
    TemplateHelper.initPasswordRemind(); // on login page
    TemplateHelper.initPasswordRemind("#header-password-remind", "#header-remindme-form"); // on header
    TemplateHelper.initPopupBasket(".popupBasket");
});

function cutTextToOuter(outer_el, inner_el) {
    var outer_height = outer_el.height();
    var inner_height = inner_el.height();
    while (inner_height > outer_height) {
        var words = inner_el.text().split(' ');
        words.pop();
        inner_el.text(words.join(' ') + '...');
        inner_height = inner_el.height();
    }
}

jQuery('window').on('load', function () {
    var cookies = document.cookie.split(';');
    if (!cookies.length) {
        document.getElementById('panel_cookie_dol').style.display = 'none';
    }

    var products = jQuery('.recommended__pbox');
    if (products.length > 0) {
        jQuery.each(products, function (i, l) {
            cutTextToOuter(jQuery(this).find('h4'), jQuery(this).find('h4 > a'));
        });
    }
});

jQuery('document').ready(function () {
    "use strict";
    // collapsable footer
    jQuery('.footer__module h3').click(function (e) {
        e.stopPropagation();
        jQuery(e.target).parent('.moduletable').toggleClass('active');
    });

    // Checking scrollDown to event tracking
    if (jQuery('body').hasClass('view-article')) {
        jQuery(window).bind('mousewheel', function (event) {
            if (event.originalEvent.wheelDelta <= 0) {
                var dataLayer = [],
                    scrollTop = jQuery(window).scrollTop(),
                    documentHeight = jQuery(document).height(),
                    windowHeight = jQuery(window).height(),
                    scrollPercent = Math.round((scrollTop / (documentHeight - windowHeight)) * 100);
                switch (scrollPercent) {
                    case 25:
                        dataLayer.push({
                            'event': 'trackEvent',
                            'eventCategory': 'view-article',
                            'eventAction': 'scrollDown',
                            'eventLabel': 25
                        });
                        break;
                    case 50:
                        dataLayer.push({
                            'event': 'trackEvent',
                            'eventCategory': 'view-article',
                            'eventAction': 'scrollDown',
                            'eventLabel': 50
                        });
                        break;
                    case 75:
                        dataLayer.push({
                            'event': 'trackEvent',
                            'eventCategory': 'view-article',
                            'eventAction': 'scrollDown',
                            'eventLabel': 75
                        });
                        break;
                    case 100:
                        dataLayer.push({
                            'event': 'trackEvent',
                            'eventCategory': 'view-article',
                            'eventAction': 'scrollDown',
                            'eventLabel': 100
                        });
                        break;
                }
            }
        });
    }


    var btn = jQuery('#scrollTopButton');

    jQuery(window).scroll(function () {
        if (jQuery(window).scrollTop() > 200) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });

    btn.click(function (e) {
        e.preventDefault();
        jQuery('html, body').animate({scrollTop: 0}, '300');
    });


    jQuery('.mobilemenu .nav.menu li').each(function () {
        // add icon to main menu
        if (!jQuery(this).hasClass('submenu') && jQuery(this).next().hasClass('submenu')) {
            jQuery(this).append(function () {
                return '<i class="fa fa-2x fa-chevron-circle-left submenu-icon"></i>';
            });
        }
        // open sub menu if main menu or sub menu is active
        if (jQuery(this).hasClass('menu_active')) {
            if (jQuery(this).hasClass('submenu')) {
                jQuery(this).parent().parent().prev().find('i').addClass('collapsed');
                jQuery(this).parent().parent().fadeIn().find('li').fadeIn();
            } else {
                jQuery(this).find('i').addClass('collapsed');
                jQuery(this).next('.nav-submenu').fadeIn().find('li').fadeIn();
            }
        }
    });


    jQuery('.submenu-icon').on('click', function (e) {
        e.preventDefault();
        jQuery(this).toggleClass('collapsed');
        var countTime = 0;
        var openMenuTime = 170;
        var fadeInTime = Math.round(openMenuTime / jQuery(this).parent().next('.nav-submenu').find('ul > li').length);
        jQuery(this).parent().next('.nav-submenu').find('ul > li').each(function (index) {
            countTime = fadeInTime * index;
            jQuery(this).delay(countTime).fadeToggle(openMenuTime);
        });
        jQuery(this).parent().next('.nav-submenu').fadeToggle(105 + countTime);
    });

    // article images zoom
    jQuery('img.zoom').attr('data-action', 'zoom');
    // clickable labels
    var labelID;
    jQuery('label').click(function (e) {
        if (e.target.nodeName !== 'A') {
            labelID = jQuery(this).attr('for');
            jQuery('#' + labelID).attr('selected', 'selected');
        }
    });
    // fix anchor scroll
    if (window.location.hash && window.location.hash === '#system-message-container') {
        jQuery('html, body').animate({
            scrollTop: jQuery(window.location.hash).offset().top - 225
        }, 400);
        return false;
    }
    // sidebar collapse
    if (jQuery('.hide__sidebar').length > 0) {
        jQuery('.hide__sidebar').click(function (e) {
            jQuery('#sidebar').slideToggle();
        });
    }

    getCartQuantity()

    // top fixed bar
    TemplateHelper.initTopBar();

    // hamburger menu
    jQuery('.hmenu').click(() => {
        toggleMenu();
    });

    function toggleMenu() {
        jQuery('#nav-icon').toggleClass('open');
        jQuery('.sitewrapper').toggleClass('sitewrapper--opened');
        jQuery('.navigation--mobile').toggleClass('opened');
        setIconOffset();
        if (!jQuery('.navigation--mobile ul li').last().isInViewport()) {
            jQuery('.scroll_down').toggleClass('scroll_show').toggleClass('scroll_hide');
        }
    }

    // scroll down mobile button
    function setIconOffset() {
        var offset = Math.round((jQuery('.navigation--mobile').width() / 2) - 20);
        jQuery('.scroll_down').css('right', offset);
    }

    jQuery.fn.isInViewport = function () {
        var elementTop = jQuery(this).offset().top;
        var elementBottom = elementTop + jQuery(this).outerHeight() - 5;
        var viewportTop = jQuery(window).scrollTop();
        var viewportBottom = viewportTop + jQuery(window).height();
        return elementBottom < viewportBottom;
    };

    function toggleScrollIcon() {

        if (jQuery('#nav-icon').hasClass('open')) {
            if (!jQuery('.navigation--mobile ul li').last().isInViewport()) {
                jQuery('.scroll_down').addClass('scroll_show').removeClass('scroll_hide');
            } else {
                jQuery('.scroll_down').removeClass('scroll_show').addClass('scroll_hide');
            }
        }
    }

    if (jQuery('.navigation--mobile').length > 0) {
        jQuery(window).resize(function () {
            setIconOffset();
            toggleScrollIcon();
        });
        var showScroll = jQuery('.navigation--mobile ul li').last().isInViewport();

        var lastScrollTop = 0;
        var delayTime = 3;
        var nextToggle = Math.round(new Date().getTime() / 1000);

        jQuery('.navigation--mobile').on('resize scroll', function () {
            var st = jQuery(this).scrollTop();
            var now = Math.round(new Date().getTime() / 1000);
            var lastLi = jQuery('.navigation--mobile ul li').last();

            if ((st > lastScrollTop || st < 10) && (lastLi !== showScroll) && (now >= nextToggle)) {
                toggleScrollIcon();
                showScroll = lastLi.isInViewport();
            }
            if (st > (jQuery('.mobilemenu').height() + jQuery('.navigation--mobile').height() - window.outerHeight) - 5 && now >= nextToggle) {
                nextToggle = now + delayTime;
            }
            lastScrollTop = st;
        });
        var lastNextToggle = '';
        setInterval(function () {
            if (Math.round(new Date().getTime() / 1000) >= nextToggle && lastNextToggle !== nextToggle) {
                toggleScrollIcon();
                showScroll = jQuery('.navigation--mobile ul li').last().isInViewport();
                lastNextToggle = nextToggle;
            }
        }, 1000);
    }
    // scroll down mobile button END

    if (jQuery('.mobilesearch').length > 0) {
        jQuery('.mobilesearch').click(function (e) {
            jQuery(this).toggleClass('active');
            jQuery('.header__row-mobile-search .site-search')
                .slideToggle()
                .find('input:first')
                .focus();
        });
    }

    initMiniCartPopUp()

    // search autocomplete
    if (jQuery('.search-query').length > 0) {
        var last_phrase = '';
        var timer;
        var is_seach_site = jQuery('body').hasClass('com_search');
        var search_input = (is_seach_site ? jQuery('#search-searchword') : jQuery(jQuery('.search-query')[0]));
        var search_form = (is_seach_site ? search_input.parent().parent() : search_input.parent());
        var search_form_hint = search_input.parent();
        search_input.attr('autocomplete', 'off');
        search_input.on('keyup click focus', function () {
            var phrase = jQuery(this).val();
            if (phrase.length >= 3) {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    if (jQuery('.autocomplete-suggestions').length > 0) {
                        jQuery('.autocomplete-suggestions').remove();
                    }
                    search_form_hint.after('<div class="autocomplete-suggestions"></div>');
                    if (phrase !== last_phrase || jQuery('.autocomplete-suggestions > div').length === 0) {
                        var url = '/index.php?option=com_snv2&task=getSearchAutocomplete&format=json&query=' + phrase;
                        jQuery.get(url, function (result) {
                            var type = '';
                            var html_row = '';
                            jQuery('.autocomplete-suggestions > div').remove();
                            var mobile = (window.outerWidth <= 680 ? true : false);

                            if (result.data.products.items.length === 0 && result.data.categories.items.length === 0 && result.data.articles.items.length === 0 && result.data.no_results) {

                                html_row = '<div class="autocomplete-suggestion category noselect">' + result.data.no_results + '</div>';
                                jQuery('.autocomplete-suggestions').append(html_row);
                            } else {
                                jQuery.each(result.data, function (index, value) {
                                    if (value.items && value.items.length > 0) {
                                        var show_more_span = (value.show_more !== '' ? '<span class="show_more right_link">' + value.show_more + '</span>' : '');
                                        html_row = '<div class="autocomplete-suggestion category noselect">' + value.label + show_more_span + '</div>';
                                        jQuery('.autocomplete-suggestions').append(html_row);

                                        jQuery.each(value.items, function (index, item) {
                                            if (mobile && index === 3) {
                                                return false;
                                            }
                                            html_row = '<div class="autocomplete-suggestion" data-index="' + index + '"><a href="' + item.link + '">' + item.name + '</a></div>';
                                            jQuery('.autocomplete-suggestions').append(html_row);
                                        });
                                    }
                                });
                            }
                        }).done(function () {
                            jQuery('.autocomplete-suggestion .show_more').on('click', function () {
                                search_form.submit();
                            });
                            jQuery('.autocomplete-suggestion > a').on('click', function (e) {
                                var phrase = jQuery(this).text();
                                var url = jQuery(this).attr('href');
                                addSearchHint(phrase, url);
                            });
                        });
                    }
                    last_phrase = phrase;
                }, 300);

            } else {
                jQuery('.autocomplete-suggestions').remove();
                var html_row = '';
                var search_hints = JSON.parse(localStorage.getItem('sn_lastsearch'));
                if (search_hints !== null && search_hints.length > 0) {
                    search_hints = search_hints.reverse();
                    search_form_hint.after('<div class="autocomplete-suggestions"></div>');
                    var clear_searches = '<span class="clear_searches right_link">' + Joomla.JText._('TPL_SN_WEBSHOP_SEARCH_LABEL_CLEAR_SEARCHES') + '</span>';
                    html_row = '<div class="autocomplete-suggestion category noselect">' + Joomla.JText._('TPL_SN_WEBSHOP_SEARCH_LABEL_RECENT_SEARCHES') + clear_searches + '</div>';
                    jQuery('.autocomplete-suggestions').append(html_row);
                    jQuery.each(search_hints, function (index, value) {
                        var search_url = value.url !== '' ? value.url : window.location.origin + '/component/search/?searchword=' + value.label + '&searchphrase=all';
                        html_row = '<div class="autocomplete-suggestion"><a href="' + search_url + '">' + value.label + '</a></div>';
                        jQuery('.autocomplete-suggestions').append(html_row);
                    });
                    jQuery('.autocomplete-suggestion .clear_searches').on('click', function () {
                        localStorage.removeItem('sn_lastsearch');
                        jQuery('.autocomplete-suggestions').remove();
                    });
                    jQuery('.autocomplete-suggestion:not(.category)').on('click', function () {
                        if (!jQuery(this).find('a').attr('href')) {
                            var phrase = jQuery(this).text();
                            search_input.val(phrase).parent().submit();
                        }
                    });
                }
            }
        });
        // add last search to local storage
        search_form.on('submit', function (e) {
            if (search_input.val() !== '') {
                var phrase = search_input.val();
                addSearchHint(phrase, '');
            }
        });
        search_input.on('focusout', function () {
            setTimeout(function () {
                jQuery('.autocomplete-suggestions').remove();
            }, 400);
        });
    }

    // add obj to localStorage
    function addToLocalStorage(ls_name, new_item, max_length, unique_fields) {
        var items = JSON.parse(localStorage.getItem(ls_name)) || [];
        if (items.length >= max_length) {
            items.shift();
        }
        var product_unique = true;
        jQuery.each(items, function (i, item) {
            jQuery.each(unique_fields, function (n, unique) {
                product_unique = (item[unique] !== new_item[unique]) ? true : false;
            });
        });
        if (product_unique) {
            items.push(new_item);
        }
        localStorage.setItem(ls_name, JSON.stringify(items));
    }

    // save product as recently_viewed_products
    if (typeof product_info !== 'undefined') {
        let products = JSON.parse(localStorage.getItem('sh_recently_viewed_products')) || [];
        products = products.filter(product => {
            if (product !== product_info.id) {
                return product;
            }
        });
        if (!products.includes(product_info.id)) {
            products.push(product_info.id);
        }
        if (products.length > 20) {
            products.shift();
        }
        localStorage.setItem('sh_recently_viewed_products', JSON.stringify(products));
    }

    function addSearchHint(label, url) {
        var item = {
            'label': label,
            'url': url
        };
        addToLocalStorage('sn_lastsearch', item, 8, ['label', 'url']);
    }

    // menu
    if (jQuery('.menu__dropline').length > 0) {
        var nav = jQuery('.sitewrapper > .navigation > .menu__layout');
        var navHeight = jQuery(nav).height();
        var items = jQuery(nav).find('> .menu__item');
        var itemHeight = jQuery(items).first().height();

        // if menu has maultiple lines
        if (navHeight / itemHeight > 1) {
            for (let i = 0; i < items.length; i++) {
                var menuBottom = jQuery(items[i]).offset().top + jQuery(items[i]).height();
                var submenu = jQuery('.menu__dropline' + jQuery(items[i]).data('id'));
                if (!submenu.length) {
                    continue;
                }
                var submenuTop = jQuery(submenu).offset().top;
                if (menuBottom === submenuTop) {
                    continue;
                }
                jQuery(submenu).css('top', '-' + (submenuTop - menuBottom) + 'px');
            }
        }

        jQuery('.menu__layout .menu__item').hover(function (e) {
            jQuery('.menu__dropline.active').removeClass('active');
            var target = jQuery(e.target);
            var selector = '.menu__dropline' + target.data('id');
            if (jQuery(selector).length > 0) {
                jQuery(selector).addClass('active');
                if (jQuery('body').hasClass('menu-dropdown')) {
                    jQuery(selector).css({left: target.position().left});
                }
            }
        });
        jQuery('.menu__dropline').mouseleave(function (e) {
            if (jQuery(e.target).hasClass('active')) {
                setTimeout(function () {
                    jQuery(e.target).removeClass('active');
                }, 1000);
            }
            if (jQuery(e.target).parent().hasClass('active')) {
                setTimeout(function () {
                    jQuery(e.target).parent().removeClass('active');
                }, 1000);
            }
        });
    }

    // promobox script
    jQuery('.promobox__rotator').each(function (i, el) {
        let pbox = jQuery(el);
        let interval = pbox.data('interval') * 1000;
        let slides = []
        let pagination = []
        let active = 0

        // add click events for images
        pbox.find('.promobox__figure img').each(function (i, slide) {
            slide = jQuery(slide);
            slides[i] = slide.parent();
            slide.click(function () {
                window.open(slides[i].data('url'), slides[i].data('target'));
            });
        });

        slides[0].addClass('promobox__figure--active');
        window.gtmPromoEvents.trackElements(slides[0]);
        // main animation loop
        if (slides.length > 1) {
            var animTime = setInterval(function () {
                slides[active].removeClass('promobox__figure--active');
                if (pagination.length > 0) {
                    pagination[active].removeClass('promobox__pagination--active');
                }

                active = (active >= slides.length - 1) ? 0 : active + 1;
                slides[active].addClass('promobox__figure--active');
                window.gtmPromoEvents.trackElements(slides[active]);
                if (pagination.length > 0) {
                    pagination[active].addClass('promobox__pagination--active');
                }
            }, interval);
        }
        // when pagination is enabled mark first dot/number as active and bind click events
        if (pbox.data('pagination') !== '0' && slides.length > 0) {
            pbox.parents().eq(2).find('.promobox__pagination li').eq(0).addClass('promobox__pagination--active');
            pbox.parents().eq(2).find('.promobox__pagination li').each(function (i, el) {
                el = jQuery(el);
                pagination[i] = el;
                el.click(function (e) {
                    clearInterval(animTime);
                    var aSlide = pbox.find('.promobox__figure--active');
                    var aPager = pbox.parents().eq(2).find('.promobox__pagination--active');
                    active = parseInt(el.html(), 10) - 1;
                    aSlide.removeClass('promobox__figure--active');
                    slides[active].addClass('promobox__figure--active');
                    aPager.removeClass('promobox__pagination--active');
                    pagination[active].addClass('promobox__pagination--active');
                });
            });
        }
        // bind events for prev/next arrows
        if (pbox.data('arrows') !== 0 && pbox.parent().find('.promobox__arrows').length > 0) {
            pbox.parent().find('.promobox__arrow--next').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                clearInterval(animTime);
                slides[active].removeClass('promobox__figure--active');
                if (pagination.length > 0) {
                    pagination[active].removeClass('promobox__pagination--active');
                }
                active = (active >= slides.length - 1) ? 0 : active + 1;
                slides[active].addClass('promobox__figure--active');
                if (pagination.length > 0) {
                    pagination[active].addClass('promobox__pagination--active');
                }
            });
            pbox.parent().find('.promobox__arrow--prev').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                clearInterval(animTime);
                slides[active].removeClass('promobox__figure--active');
                if (pagination.length > 0) {
                    pagination[active].removeClass('promobox__pagination--active');
                }

                active = (active <= 0) ? slides.length - 1 : active - 1;
                slides[active].addClass('promobox__figure--active');
                if (pagination.length > 0) {
                    pagination[active].addClass('promobox__pagination--active');
                }
            });
        }
    });

    jQuery('form#newsletter > input[type="text"], input[type="email"]').on('focus', function () {
        jQuery('div.row-snrecaptcha').css({
            'height': 'auto',
            'display': 'table',
        });
        jQuery('div.row-snrecaptcha').slideDown('400', function () {
            jQuery('div.row-snrecaptcha').css({
                'visibility': 'visible',
            });
        });
    });

    /*
    * Toggle password visiblity in registration form
    */
    initTogglePasswordVisibility('#jform_password1');
    initTogglePasswordVisibility('#modlgn-passwd');
});

jQuery(window).scroll(function () {
    var pos = jQuery(window).scrollTop();
    if (jQuery('.topbar').length > 0 && jQuery('.hbox').length > 0) {
        if (pos > jQuery('.hbox').offset().top) {
            if (!jQuery('.topbar').hasClass('active')) {
                jQuery('.topbar').addClass('active');
            }
        } else {
            if (jQuery('.topbar').hasClass('active')) {
                jQuery('.topbar').removeClass('active');
            }
        }
    } else {
        if (pos > 400 && !jQuery('.topbar').hasClass('active')) {
            jQuery('.topbar').addClass('active');
        } else if (pos < 400 && jQuery('.topbar').hasClass('active')) {
            jQuery('.topbar').removeClass('active');
        }
    }
});


jQuery(window).on('load', function () {
    jQuery('.scroll_down').css('display', 'block');
    eventTracking();
});

/**
 * Provides requestAnimationFrame in a cross browser way.
 * @author paulirish / http://paulirish.com/
 */


// Setup a listener to track Add to Homescreen events.
window.addEventListener('beforeinstallprompt', function (e) {
    e.userChoice.then(function (choiceResult) {
        ga('send', 'event', 'A2H', choiceResult.outcome);
    });
});
jQuery('body').on('click', '.promoproduct__cart, .product__readmore, .product__title', function () {
    var productData = JSON.parse(jQuery(this).parents('.product-animated').attr('data-gtm'));
    if (dataLayer) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            "ecommerce": {
                "currencyCode": currencyCode,
                "click": {
                    "actionField": {
                        "list": "Webshop"
                    },
                    "products": [{
                        "name": productData.name,
                        "id": productData.id,
                        "price": productData.price,
                        "category": productData.category,
                        "position": productData.position
                    }]
                }
            },
            "event": "ProductClick"
        });
    }
});

const eventTracking = function () {
    jQuery('[data-event="ev"]').click(function () {
        var evCat = jQuery(this).data('category') ? jQuery(this).data('category') : '',
            evAct = jQuery(this).data('action') ? jQuery(this).data('action') : '',
            evLab = jQuery(this).data('label') ? jQuery(this).data('label') : '',
            evVal = jQuery(this).data('value') ? jQuery(this).data('value') : '';
        try {
            dataLayer.push({
                'event': 'trackEvent',
                'eventCategory': evCat,
                'eventAction': evAct,
                'eventLabel': evLab,
                'eventValue': evVal,
            });
        } catch (e) {
            console.log(e);
        }
    });


    jQuery('.upsell a').click(function () {
        try {
            dataLayer.push({
                'event': 'trackEvent',
                'eventCategory': 'ws-thankYouPage',
                'eventAction': 'upsellClick',
                'eventLabel': '',
                'eventValue': '',
            });
        } catch (e) {
            console.log(e);
        }
    });

    if (jQuery('#system-message .alert-message').length) {
        jQuery("#system-message .alert-message").each(function () {
            if (jQuery(this).text() === Joomla.JText._('COM_USERS_REGISTER_USERNAME_MESSAGE')) {
                jQuery(this).parent().html(Joomla.JText._('COM_SNV2_LP_USER_EXISTS_MESSAGE'));
            }
        });
    }

    // send magic link
    jQuery(document).on('click', '#sendMagicLink', function (e) {
        e.preventDefault();
        e.stopPropagation();
        jQuery('label.has-error').remove();
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        var email = jQuery('#jform_email').val();
        var token = jQuery('#member-registration input[type=hidden][value="1"]').last().attr('name');
        var dataToSend = {
            'format': 'json',
            'returnUrl': encodeURI(window.location.href),
            'email': jQuery('#jform_email').val()
        };
        if (email === '') {
            jQuery('#jform_email').css('border', 'solid 1px red');
            jQuery('#jform_email').after('<label class="has-error">' + Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED') + '</label>');
            return false;
        } else if (!emailReg.test(email)) {
            jQuery('#jform_email').css('border', 'solid 1px red');
            jQuery('#jform_email').after('<label class="has-error">' + Joomla.JText._('COM_SNV2_EMAIL_NOT_VALID') + '</label>');
            return false;
        } else {
            jQuery('#jform_email').css('border', 'solid 1px #cdcdcd');
        }
        dataToSend[token] = '1';
        jQuery.ajax({
            type: 'POST',
            url: 'index.php?option=com_snv2&task=sendMagicLink',
            data: dataToSend
        }).done(function (data) {
            if (data.success && data.data === true) {
                jQuery('#successMessage').css('display', 'block');
                jQuery('#failsafeMessage').css('display', 'none');
                jQuery('#magicLinkSuccess').modal('show');
            } else {
                jQuery('#successMessage').css('display', 'none');
                jQuery('#failsafeMessage').css('display', 'block');
                jQuery('#magicLinkSuccess').modal('show');
            }
        });
    });
};

function initMiniCartPopUp() {
    const $headerCart = jQuery('.header__cart')
    const $headerCartMini = jQuery('.header__cart-mini')
    if ($headerCart.length && $headerCartMini.length) {
        const MOBILE_BREAK_POINT = 680
        $headerCart.on('click', function (e) {
            if (window.outerWidth <= MOBILE_BREAK_POINT) {
                return
            }
            e.preventDefault()
            e.stopPropagation()

            const miniCart = jQuery(this).hasClass('header__cart-second')
                ? jQuery('.header__cart-mini-second')
                : $headerCartMini.first()
            const isMiniCartOpen = miniCart.hasClass('fadeInDown')
            miniCart.toggleClass('fadeOutUp', isMiniCartOpen)
            miniCart.toggleClass('fadeInDown', !isMiniCartOpen)
        })
    }
}
