import sendAjaxRequest from './sendAjaxRequest'
import {getCartDetails} from './helpers'

export default class TemplateHelper {
    static setBottomButtonPadding() {
        setTimeout(() => {
            if (jQuery('.stickyMobileBottomBar:visible').length) {
                const buttonHeight = jQuery('.stickyMobileBottomBar:visible').outerHeight()
                jQuery('body').attr('style', `padding-bottom: ${buttonHeight}px!important;`)
            }
        }, 100)
    }

    static scrollToInvalidField() {
        jQuery('html, body').animate({
            scrollTop: jQuery('input.has-error').first().parent().offset().top - 100
        }, 300)
    }

    static initPasswordRemind(
        buttonSelector = '#password-remind',
        formSelector = '#remindme-form'
    ) {
        if (jQuery(buttonSelector).length) {
            jQuery(buttonSelector).on('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                jQuery(formSelector).slideToggle()
            })
        }
    }

    static initFullWidthMenu() {
        // When the menu has multiple rows and 'full-width' is on, add an element after the last menu item, that will align the last row to the left.
        if (jQuery('.navigation.full-width-menu')) {
            const $menuLayout = jQuery('.navigation.full-width-menu .menu__layout')
            const $menuItems = $menuLayout.find('> .menu__item')
            if ($menuLayout && $menuItems) {
                if (
                    $menuLayout.outerHeight() < $menuItems.first().outerHeight()
                ) {
                    $menuItems.last().after('<li style="flex:auto;"></li>')
                }
            }
        }
    }

    static initPopupBasket(selector) {
        jQuery(selector).on('click', function(e) {
            e.preventDefault()
            jQuery('.modal-header h5').html(Joomla.JText._('COM_SNV2_RETURNS_CHECKING'))
            const isMostPopular = jQuery('body').hasClass('isFrontpage') && jQuery(this).hasClass('xcolumns_addtocart')
            let form
            if (isMostPopular) {
                jQuery('input[name=\'pId\']').val(jQuery(this).attr('id'))
                form = jQuery('form#xcolumns_cart')
            } else {
                form = jQuery(this).closest('form')
            }
            const post_url = form.attr('action')
            const form_data = form.serialize()

            jQuery('.productPlace').html('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>')

            let fields = {
                'form' : jQuery('#cart__form').serializeArray(),
                'pid': jQuery(this).data('pid'),
                'type': 'JSON'
            }
            let promise = sendAjaxRequest('basketfront', 'addToCart', fields, false)
            promise.then(function(msg) {
                let data = msg.data.data,
                    addedProduct = ''
                if(data.status === true) {
                    addedProduct += `<div class="row">`
                    addedProduct += `<div class="col-md-3 col-sm-4 col-xs-5 minicart__image"><img src="${msg.data.data.additionalData.img}" alt="${msg.data.data.additionalData.title}" title="${msg.data.data.additionalData.title}" /></div>`
                    addedProduct += `<div class="col-md-9 col-sm-8 col-xs-7 minicart__title"><div class="product-name">${msg.data.data.additionalData.title}</div><div class="minicart__price">${msg.data.data.additionalData.price}</div></div>`
                    addedProduct += `</div>`
                    jQuery('.modal-header h5').html(jQuery('.modal-header h5').data('success'))
                    jQuery('.productPlace').html(addedProduct)
                } else {
                    addedProduct += `<div class="row">`
                    addedProduct += `<div class="col-md-12 col-sm-8 col-xs-7 minicart__title">${data.msg}</div>`
                    addedProduct += `</div>`
                    jQuery('.modal-header h5').html(jQuery('.modal-header h5').data('error'))
                    jQuery('.productPlace').html(addedProduct)
                }
                getCartDetails()
            }).catch(err => {
                console.log(err)
            })
        })
    }

    static initTopBar() {
        if (jQuery('#topbar').length > 0) {
            if (jQuery('.top-banner').length) {
                jQuery('.top-banner').clone().appendTo('#topBanner')
                const topBannerHeight = jQuery('.top-banner').outerHeight()
                jQuery('.sitewrapper').css('padding-top', `${topBannerHeight}px`)
                jQuery('.topbar')
                    .css('padding-top', `${topBannerHeight}px`)
                    .css('top', `-=${topBannerHeight}`)
                jQuery('.navigation--mobile').css('top', `+=${topBannerHeight}`)
            }
            jQuery('.infobar__inner').clone().appendTo('#topBarInfo')
            jQuery('.menu__layout').clone().appendTo('#topBarMenu')
            jQuery('.header__cart').clone().appendTo('#topBarMenu .menu__layout').addClass('header__cart-second')
            jQuery('.header__cart-mini').clone().appendTo('#topBarMenu .menu__layout').addClass('header__cart-mini-second')
            jQuery('a.header__cart-second, div.header__cart-mini-second').wrapAll('<div class="minicart header__item" />')
        }
    }
}
